* {
  font-family: "roboto", sans-serif;
  margin: 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(38, 178, 11, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(38, 178, 11, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(38, 178, 11, 0);
  }
}

/* Chat messages styling (customize as needed) */
.widget-chat-messages {
  padding: 10px;
  height: inherit;
  overflow-y: auto !important;
}

/* Chat input field styling (customize as needed) */
.widget-chat-input {
  color: black;
  border: none !important;
  background-color: transparent;
  width: 85%;
  height: 100%;
  max-height: 30px;
  overflow-y: auto;
  padding-left: 13px;
  padding-top: 14px;
  resize: none;
}

.widget-chat-input:focus {
  outline: none;
  border: none !important;
}

.minimize-button {
  color: white;
  font-size: 20px;
  border-radius: 100%;
  padding: 7px 10px 7px 10px;
  border: unset;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px -1px black, 0 2px 1px -1px black;
  transition: all 0.3s ease;
}

.minimize-button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.minimize-button:focus-visible {
  outline: none;
}
.minimize-button:focus {
  outline: none;
}

.trash-button {
  color: white;
  font-size: 20px;
  border-radius: 100%;
  padding: 8px 9px 8px 9px;
  border: unset;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px -1px black, 0 2px 1px -1px black;
  transition: all 0.3s ease;
}

.trash-button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.trash-button:focus-visible {
  outline: none;
}
.trash-button:focus {
  outline: none;
}

/* User message styling */
.widget-user-message {
  text-align: right;
  padding: 8px 10px;
  background-color: #f0f0f0;
  max-width: 90%;
  color: black;
  margin: 5px;
  border-radius: 20px;
  border-top-right-radius: 0px;
  clear: both;
  float: right;
  word-wrap: break-word;
  white-space: normal;
}

.widget-user-message p {
  margin: 0;
}

.widget-chatbot-message p {
  margin: 0;
}

/* Chatbot message styling */
.widget-chatbot-message {
  text-align: left;

  color: black;
  padding: 8px 10px;
  width: 90%;
  border-radius: 20px;
  border-top-left-radius: 0px;
  clear: both;
  float: left;
}

/* Send button styling */
.widget-send-button {
  height: 40px;
  margin: 3px;
  border: none;
  cursor: pointer;
  width: 40px;
  border-radius: 100%;
}
.widget-send-button:focus-visible {
  outline: none;
}
.widget-send-button:focus {
  outline: none;
}
.widget-send-button:hover {
  outline: none;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 4px;
  animation: blink 1.5s infinite;
  background-color: black;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 1;
  }
}

.header-icon {
  width: 70px;
  height: 70px;
  padding: 10px;
}

.widget-header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.widget-header-title {
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-chat-footer {
  display: block;
}

.widget-chat-input-container {
  display: flex;
  justify-content: space-between;
  height: auto;
  max-height: 150px;
  border-radius: 25px;
  overflow-y: hidden;
  background-color: whitesmoke;
  margin: 10px;
  display: flex;
  border: 2px solid #f0f0f0;
}

.message-attachment {
  border-radius: 10px;
  width: 100%;
  margin: 10px 0px 10px 0px;
  min-width: 300px;
}

.message-attachment div {
  border-radius: 10px;
}

.header-title-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
}

.header-description {
  font-size: 13px;
  color: whitesmoke;
}

.message-time {
  padding-top: 2px;
  font-size: 10px;
  text-align: right;
  color: #707070;
}

/* WideImageViewer.css */

.pannellum-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.pnlm-container {
  width: inherit !important;
  height: inherit !important;
}

.custom-fullscreen-button {
  position: absolute; /* Position the button */
  top: 10px; /* Adjust top position */
  right: 10px; /* Adjust right position */
  padding: 10px 13px; /* Button padding */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 9999; /* Ensure it is on top */
}

.custom-fullscreen-button:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker on hover */
}

.pnlm-load-box {
  display: none !important;
}
.shortcut-buttons {
  display: flex;
  margin: 10px 0px 5px 0px;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.shortcut-button {
  background-color: white;
  border: unset;
  border-radius: 14px;
  font-size: 1rem;
  width: 300px;
  padding: 4px 0px 4px 0px;
  box-shadow: 0 2px 6px -1px var(--primary-color),
    0 2px 2px -1px var(--primary-color);
  transition: all 0.3s ease;
}

.shortcut-button:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.shortcut-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.chat-icon {
  transition: transform 0.1s ease-in-out;
}

.widget-chat-button:hover .chat-icon {
  transform: scale(1.15); /* Zoom in when hovered */
}

.chat-tooltip-desktop {
  position: absolute;
  bottom: 100px;
  right: 0px;
  padding: 0;
  width: 260px;
  color: black;
  pointer-events: none;
  z-index: 9999;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.chat-tooltip-text-desktop {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  font-size: medium;
  line-height: 1.5;
  padding: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.chat-tooltip-mobile {
  position: absolute;
  bottom: 80px;
  right: 0px;
  padding: 0;
  width: 220px;
  color: black;
  pointer-events: none;
  z-index: 9999;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.chat-tooltip-text-mobile {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  font-size: 0.85rem;
  line-height: 1.5;
  padding: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* Show the tooltip when hovering the button */
.widget-chat-button:hover {
  opacity: 1; /* Make it visible */
  transform: translateY(0); /* Slide up */
}

.slider-cta-button {
  padding: 15px 15px 15px 15px;
  border-radius: 25px;
  border: 2px solid var(--primary-color);
  font-weight: bold;
  background-color: var(--primary-color);
  font-size: large;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.slider-cta-button:hover {
  color: rgb(33, 33, 33);
  background-color: white;
}

.slider-minimize-button {
  position: absolute; /* Position the button */
  top: 10px; /* Adjust top position */
  left: 10px; /* Adjust right position */
  padding: 10px 13px; /* Button padding */
  background-color: unset; /* Semi-transparent background */
  color: white; /* Text color */
  border: none; /* No border */
  font-size: large;
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 9999; /* Ensure it is on top */
  transition: transform 0.1s ease-in-out; /* Smooth transition */
}

.slider-minimize-button:hover {
  transform: scale(1.2);
}

.image-slideshow-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.image-slideshow-navigation-button {
  position: absolute;
  top: 50%;
  font-size: xx-large;
  opacity: 0.5;
  transform: translateY(-50%);
  background-color: unset;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.image-slideshow-dot-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.image-slideshow-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
.powered-by {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.handoff-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: 100%;
  padding: 2rem;
  background: linear-gradient(to top, #fff 70%, var(--primary-color));
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.custom-fullscreen-button {
  position: absolute; /* Position the button */
  top: 10px; /* Adjust top position */
  right: 10px; /* Adjust right position */
  padding: 10px 13px; /* Button padding */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  border: 1px solid rgb(192, 192, 192); /* No border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 9999; /* Ensure it is on top */
}

.handoff-minimize-button {
  position: absolute; /* Position the button */
  top: 20px; /* Adjust top position */
  right: 0px; /* Adjust right position */
  color: white;
  font-size: 20px;
  border-radius: 100%;
  padding: 10px 13px 10px 13px;
  border: unset;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.handoff-minimize-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.handoff-minimize-button:active {
  background-color: rgba(0, 0, 0, 0.2);
  transform: scale(0.9);
}

.custom-fullscreen-button:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker on hover */
}

.handoff-form-header {
  text-align: center;
  margin-bottom: 1rem;
}

.handoff-form-header h2 {
  font-size: 1.8rem;
  color: #333;
}

.handoff-form-header p {
  font-size: 1rem;
  color: #666;
}

.handoff-form-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.handoff-form-input input {
  width: 90%;
  padding: 1rem;
  border: 1px solid #ddd;
  font-size: 1rem;
  background: white;
  transition: border-color 0.3s ease;
}

.handoff-form-input input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--primary-color);
}

.handoff-form-button {
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background: var(--primary-color);
  color: white;
  font-size: 1.1rem;
  margin-top: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.handoff-form-button:hover {
  background: var(--primary-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.handoff-form-button:active {
  background: var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: scale(0.98);
}
